<!--
 * @Author: Haoxiwei 1078734933@qq.com
 * @Date: 2023-04-27 11:17:12
 * @LastEditors: Haoxiwei 1078734933@qq.com
 * @LastEditTime: 2023-04-27 13:08:37
 * @FilePath: /WeMeet_admin_project/wemeet-admin/src/views/exhibitionManage/components/editRelated.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone">
          <template #append>
            <el-button type='success' plain @click="searchHostInfo()">查询信息</el-button>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="用户信息" v-if="!!hostInfo">
        <div class="userInfoBox">
          <el-avatar :size="size" :src="hostInfo.avatar" />
          <div class="detailInfo">
            <div>{{ hostInfo.name }}</div>
            <div>{{hostInfo.company}} {{ hostInfo.position }}</div>
          </div>
        </div>
      </el-form-item>

    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { getMeetingHostByPhone, relatedOrgInfo } from '@/api/exhibitionManage'

export default defineComponent({
  name: 'editRelated',
  emits: ['fetch-data'],
  props: {
    meetingCode: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      form: {
        phone: '',
      },
      size: 'large',
      rules: {
        roleName: [
          { required: true, trigger: 'blur', message: '请输入角色名称' },
        ],
      },
      title: '关联主办方',
      dialogFormVisible: false,
      list: [],
      hostInfo: null,
    })

    const showEdit = async (row) => {
      state.dialogFormVisible = true
    }
    const close = () => {
      state.form = { phone: '' }
      state.hostInfo = null
      state.dialogFormVisible = false
    }

    const searchHostInfo = async () => {
      if (!!state.form.phone) {
        try {
          let phoneParams = { phone: state.form.phone }
          const { data: hostInfo } = await getMeetingHostByPhone(phoneParams)
          state.hostInfo = hostInfo
        } catch (e) {
          state.hostInfo = null
        }
      } else {
        proxy.$baseMessage(`手机号不能为空`, 'error', 'vab-hey-message-error')
      }
    }

    const save = async () => {
      if (!!state.hostInfo) {
        try {
          const { data, msg } = await relatedOrgInfo(
            state.hostInfo.uid,
            props.meetingCode
          )
          proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
          close()
          emit('fetch-data')
        } catch (e) {
          proxy.$baseMessage(`系统错误，请重试`, 'error', 'vab-hey-message-error')
        }
      } else {
        proxy.$baseMessage(`用户信息不能为空`, 'error', 'vab-hey-message-error')
      }
    }
    return {
      ...toRefs(state),
      showEdit,
      close,
      searchHostInfo,
      save,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}

.userInfoBox {
  display: flex;
  justify-content: left;
  background: #67c23a;
  padding: 10px;
  box-sizing: border-box;
  .detailInfo {
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
  }
}
</style>
